<template>
  <div class="fabirc-design">
    <svg
      v-show="showPosLine"
      width="1000"
      height="2"
      class="line"
      stroke-width="3"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <line x1="2" y1="0" x2="1700" y2="0" stroke-dasharray="20 20" stroke="#3841DB" />
    </svg>

    <svg
      v-show="showPosLine"
      width="1000"
      height="2"
      class="line h-line"
      stroke-width="3"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <line x1="2" y1="0" x2="1700" y2="0" stroke-dasharray="20 20" stroke="#3841DB" />
    </svg>

    <vue-fabric
      class="fabric-wrapper"
      :ref="id"
      :id="id"
      :width="width"
      :height="height"
      :markerWitdth="markerWitdth"
      :markerHeight="markerHeight"
      @selection:created="selectionCreated"
      @selection:updated="selectionUpdated"
      @selection:cleared="selectionCleared"
      @mouse:down="mouseDown"
      @mouse:up="mouseUp"
      @object:rotating="objectRotating"
      @object:rotated="objectRotated"
      @object:scaled="objectScaled"
      @object:moving="objectMoving"
      @after:render="afterRender"
    ></vue-fabric>
  </div>
</template>

<script>
import { commonFabricDesignEventMixin, gestureMixin } from '../../../mixins'
import { mapMutations } from 'vuex'
export default {
  mixins: [commonFabricDesignEventMixin, gestureMixin],

  props: {
    data: {
      type: Object
      //   required: true
    },
    id: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      fabricLoading: true,
      width: 1000,
      height: 1000,
      markerWitdth: 1000,
      markerHeight: 1000,
      showPosLine: false
    }
  },

  mounted() {
    this.setFabric()
  },

  computed: {
    fabircCanvas({ id }) {
      return this.$refs[id] ? this.$refs[id] : null
    }
  },

  methods: {
    ...mapMutations(['SET_CUSTOM_DESIGN_INSTANCE']),

    setFabric() {
      this.SET_CUSTOM_DESIGN_INSTANCE(this.fabircCanvas)
    }
  }
}
</script>

<style lang="scss" scoped>
.fabric-wrapper {
  padding: 1px;
  border: 1px solid $border-color;
  width: 100%;
  height: 100%;
  ::v-deep {
    .canvas-container {
      width: 100% !important;
      height: 100% !important;
      canvas {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}
.fabirc-design {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ::v-deep {
    .el-icon-loading {
      font-size: 35px;
    }
  }
  .line {
    position: absolute;
    z-index: 1000;
    display: inline-block;
    opacity: 0.7;
  }
  .h-line {
    transform: rotate(90deg);
  }
}
</style>
