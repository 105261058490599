<template>
  <div class="app-container">
    <el-row :gutter="15">
      <el-col :span="4" class="left-col">
        <DesignChunk :key="designChunkId" />
      </el-col>
      <el-col :span="16" class="main-col">
        <div class="fabirc-area_container">
          <div class="fabirc-area">
            <FabricDesign :id="fabricId" />
          </div>
        </div>
      </el-col>
      <el-col :span="4" class="right-col">
        <div class="operators-wrapper">
          <LineOperators v-if="isExistActiveObject" />
        </div>
        <div class="preveiw-wrapper">
          <Preview />
        </div>
        <DoneBtnWrapper v-bind="permissionsList" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import DesignChunk from './module/designChunk'
import DoneBtnWrapper from './module/doneBtnWrapper'
import FabricDesign from './module/fabricDesign'
import LineOperators from './module/lineOperators'
import Preview from './module/preview'

import { getUUID } from '@/utils'
import { Control } from '@/components/fabric/control'
import { fabricInstanceMixin } from '../../mixins'

import { customLayoutDetail as getDetail } from '@/api/product/customFloorApi'

export default {
  mixins: [fabricInstanceMixin],

  provide() {
    return {
      canvasModifiedHandler: this.canvasModifiedRenderHandler,
      isEdit: this.isCustomFloorEdit
    }
  },

  components: {
    DesignChunk,
    DoneBtnWrapper,
    FabricDesign,
    LineOperators,
    Preview
  },

  props: {
    isCustomFloorEdit: Boolean,

    hasDoAdd: Boolean,
    hasDoEdit: Boolean,
    hasDetail: Boolean
  },

  data() {
    return {
      loading: false,
      id: this.$route.query.id,
      designChunkId: getUUID(),
      fabricId: getUUID()
    }
  },

  created() {
    this.getDetail()
  },

  destroyed() {
    this.CLEAR_DESIGN_STATE()
  },

  computed: {
    permissionsList({ hasDoAdd, hasDoEdit, hasDetail, isCustomFloorEdit }) {
      return {
        hasDoSubmit: isCustomFloorEdit ? hasDoEdit : hasDoAdd,
        hasDetail
      }
    },
    // 监听当前是否有激活对象
    isExistActiveObject() {
      return !!this.curFabircActiveObject
    }
  },

  watch: {
    curFabircActiveObjectScale: {
      handler() {
        this.updateLineWidth()
      },
      deep: true
    }
  },

  methods: {
    /**
     * @description: 编辑时候获取定制数据
     * @param {*}
     * @return {*}
     */
    async getDetail() {
      if (!this.isCustomFloorEdit || !this.permissionsList.hasDetail) return
      try {
        this.loading = true
        const { code, detail } = await getDetail(this.id)
        if ($SUC({ code })) {
          this.SET_CUSTOM_FLOOR_DATA(detail || {})
          //重新更新designChunkId，designChun重新走生命周期
          this.designChunkId = getUUID()
        }
      } finally {
        this.loading = false
      }
    },

    canvasModifiedRenderHandler() {
      const curLayer = this.getActiveObject()
      this.SET_CUSTOM_FLOOR_RENDER_FINISH(false)
      if (curLayer) {
        const controlOfCanvas = new Control(this.instance.canvas)
        controlOfCanvas.initMoveControl()
        curLayer.setCoords()
        this.instance.renderAll()
      }
      setTimeout(() => {
        this.SET_CUSTOM_FLOOR_RENDER_FINISH(true)
      }, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-row {
  height: 100%;
  .fabirc-area_container {
    position: relative;
    padding-top: calc(100% - 80px);
    width: calc(100% - 80px);
    @media screen and (min-width: 2000px) {
      padding-top: 1000px;
      width: 1000px;
    }
    .fabirc-area {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
  .left-col,
  .right-col {
    padding: 10px;
    border: 1px solid $border-color;
    height: 100%;
  }
  .main-col {
    display: flex;
    justify-content: center;
    padding: 0 40px;
  }
  .right-col {
    @include vCenter;
    justify-content: space-between;
  }
  .operators-wrapper {
    flex: 1;
  }
  .preveiw-wrapper {
    margin: 15px 0;
  }
}
</style>