<template>
  <div class="preview-component">
    <div class="img-wrapper">
      <el-image :src="url" v-if="url"></el-image>
    </div>
  </div>
</template>

<script>
import { fabricInstanceMixin } from '../../../mixins'
export default {
  mixins: [fabricInstanceMixin],

  data() {
    return {
      url: ''
    }
  },

  created() {
    this.editInitUrl()
  },

  watch: {
    customFloorRenderFinish(newVal) {
      if (newVal) {
        this.url = this.instance.canvas.toDataURL({
          format: 'png',
          quality: 1
        })
      }
    }
  },

  methods: {
    editInitUrl() {
      if (this.isEdit) {
        this.url = this.customFloorData.displayImagePath
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.preview-component {
  width: calc(100% - 40px);
  padding-top: calc(100% - 40px);
  margin-left: 20px;
  position: relative;
  .img-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid $border-color;
  }
}
</style>