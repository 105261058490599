import { render, staticRenderFns } from "./fabricDesign.vue?vue&type=template&id=00c1709e&scoped=true&"
import script from "./fabricDesign.vue?vue&type=script&lang=js&"
export * from "./fabricDesign.vue?vue&type=script&lang=js&"
import style0 from "./fabricDesign.vue?vue&type=style&index=0&id=00c1709e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00c1709e",
  null
  
)

export default component.exports